<script setup>
const props = defineProps({
    class: String,
    value: String,
    error: Boolean,
    color: String,
});

const classes = () => {
    let output = props.class && props.class.length ? [props.class] : ['label text-sm'];

    if (props.error) {
        output.push('placeholder:text-error text-error')
    }

    if (props.color) {
        output.push(`text-${props.color}`)
    }

    return output.join(' ')
}

</script>

<template>
    <label :class="classes()">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot /></span>
    </label>
</template>
